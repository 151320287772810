<route>
{
  "meta": {
    "permission": [
      "currencies.view_currency"
    ]
  }
}
</route>

<template>
  <div>
    <i-table
      :title="$tc('currency', 2)"
      :headers="headers"
      app="currencies.currency"
      :items="currrenciesItems"
      dontCreate
      dontRemove
      @click:edit="changeRoute"
    >
      <template v-slot:item.example="{ item }">
        <i-money :value="8000" mode="text" :currency="getCurrency(item)" />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  created() {
    if (this.$store.getters['utils/getIsloading']) {
      this.$store.dispatch('utils/actionLoading')
    }
  },
  data() {
    return {
      currrenciesItems: []
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'company/getCurrencies',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    headers() {
      return [
        {
          text: this.$t('code'),
          value: 'code'
        },
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('example'),
          value: 'example'
        },
        {
          text:
            this.currencyBtn.delete || this.currencyBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.currencyBtn.delete || this.currencyBtn.edit ? 'actions' : ''
        }
      ]
    },
    currencyBtn() {
      return {
        delete:
          this.getPermissions([`currencies.delete_currency`]) || this.isAdmin,
        edit:
          this.getPermissions([`currencies.change_currency`]) || this.isAdmin
      }
    }
  },
  watch: {
    currencies: {
      immediate: true,
      deep: true,
      handler(val) {
        this.currrenciesItems = val()
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'currency-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    getCurrency(item) {
      return {
        locale: item.country[0].lang,
        style: 'currency',
        currency: item.code
      }
    }
  }
}
</script>
